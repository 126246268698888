import ErrorComponent, { ErrorProps } from "~/components/error/ErrorComponent";
import PageWithHeader from "~/components/layout/PageWithHeader";

/** ErrorComponent within PageWithHeader */
const FullErrorContainer = (props: ErrorProps): JSX.Element => {
  return (
    <PageWithHeader>
      <ErrorComponent
        statusCode={props.statusCode}
        title={props.title}
        error={props.error}
      />
    </PageWithHeader>
  );
};

export default FullErrorContainer;
