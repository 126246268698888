import { useEffect, useState } from "react";
import FullErrorContainer from "~/components/error/FullErrorContainer";
import Router, { useRouter } from "next/router";
import useLightUserInfo from "~/network/useLightUserInfo";
import type { Page } from "~/utils/page";

const IndexPage: Page = () => {
  const { userId, isWritableUser, landingPage } = useLightUserInfo();
  const [error, setError] = useState<Error | null>(null);
  const router = useRouter();
  const path = router.asPath;

  useEffect(() => {
    const url = new URL(path, window.location.toString());
    const basePath = url.pathname;

    if (basePath !== "/") {
      // If the route exists, calling this will go to the correct place. If it doesn't, it will throw an error.
      Router.replace(path).catch((replacementError) => {
        console.warn("Index.html/router warn: ", path, " is not a valid path.");
        setError(replacementError);
      });
      return;
    }

    if (userId) {
      let dest = "/resources";
      if (landingPage) {
        dest = landingPage;
      } else if (isWritableUser) {
        dest = "/latest";
      }

      Router.replace(dest).catch(console.error);
    }
  }, [isWritableUser, landingPage, path, userId]);

  if (error) {
    return <FullErrorContainer statusCode={404} error={error} />;
  }

  return null;
};

export default IndexPage;
