import {
  ArrowLeft2Icon,
  ArrowRight2Icon,
  PlaylistCasetteIcon,
} from "@sequoiacap/client-ui/icons";
import { Text } from "@sequoiacap/client-ui";
import { TrackEvent, track } from "~/utils/analytics";
import { getFirstParam } from "@sequoiacap/shared/utils/getFirstParam";
import { useGetLibraryEntry } from "~/network/library-api";
import { useRouter } from "next/router";
import NextLink from "~/components/link/NextLink";
import classNames from "classnames";
import styles from "./PlaylistNavigation.module.scss";

export const PlaylistNavigation = () => {
  const router = useRouter();
  const playlistId = getFirstParam(router.query.pl);
  const storyId = getFirstParam(router.query.story);
  const { data: libraryEntry } = useGetLibraryEntry(playlistId || null);

  if (!playlistId || !libraryEntry || !libraryEntry.playlistContentMeta) {
    return null;
  }

  const curStoryIndex = libraryEntry?.playlistContentMeta?.findIndex(
    (item) => item.id === storyId,
  );
  const nextStoryIndex = curStoryIndex + 1;
  const prevStoryIndex = curStoryIndex - 1;
  const nextStory = libraryEntry.playlistContentMeta[nextStoryIndex];
  const prevStory = libraryEntry.playlistContentMeta[prevStoryIndex];
  const playlistLink = `/resources?story=${playlistId}`;

  return (
    <div
      className={styles.base}
      onMouseUp={(ev) => {
        track(TrackEvent.playlistNavClick, {
          playlistId,
          storyId,
          target: (ev.target as any)?.getAttribute?.("data-track"),
        });
      }}
    >
      <nav className={styles.container}>
        <Text
          as={NextLink}
          className={styles.title}
          href={playlistLink}
          variant="body2Sans"
        >
          <PlaylistCasetteIcon /> {libraryEntry?.title}
        </Text>
        <div className={styles.listContainer}>
          <NextLink
            href={prevStory?.link}
            data-track="pl-prev"
            disabled={!prevStory}
            className={classNames(
              styles.item,
              styles.icon,
              !prevStory && styles.disabled,
            )}
          >
            <ArrowLeft2Icon />
          </NextLink>
          <Text
            as="ol"
            className={classNames(styles.list, "styledScrollbar")}
            variant="label1"
          >
            {libraryEntry.playlistContentMeta.map((item, index) => (
              <li key={item.id}>
                <NextLink
                  className={classNames(
                    styles.item,
                    item.id === storyId && styles.active,
                  )}
                  href={item.link}
                  data-track={`pl-story-${index + 1}`}
                >
                  {index + 1}
                </NextLink>
              </li>
            ))}
          </Text>
          <NextLink
            className={classNames(styles.item, styles.icon)}
            href={nextStory ? nextStory.link : playlistLink}
            data-track="pl-next"
          >
            <ArrowRight2Icon />
          </NextLink>
        </div>
      </nav>
    </div>
  );
};
