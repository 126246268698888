import { PlaylistNavigation } from "~/components/library/PlaylistNavigation";
import { isDev } from "~/network/swr-firebase/helpers/is-dev";
import { isFromAmpersandWebVC } from "~/network/ampersand-web-vc-bridge";
import Head from "next/head";
import Header, { HeaderProps } from "../header/Header";
import classNames from "classnames";
import styles from "./PageWithHeader.module.scss";

type PageWithHeaderProps = HeaderProps & {
  noMaxWidth?: boolean;
  noPadding?: boolean;
  fillSpace?: boolean;
  pageClassName?: string;
};

export default function PageWithHeader({
  children,
  noMaxWidth,
  noPadding,
  fillSpace,
  ...props
}: PageWithHeaderProps): JSX.Element {
  return (
    <>
      <Head>
        <title>
          {isDev ? "!!YOU SHOULD ADD A PAGE TITLE!!!!" : "Ampersand"}
        </title>
      </Head>
      {!isFromAmpersandWebVC() && <Header {...props} />}
      <main
        className={classNames(
          styles.mainContainer,
          noMaxWidth && styles.noMaxWidth,
          noPadding && styles.noPadding,
          fillSpace && styles.fillSpace,
          props.pageClassName,
        )}
      >
        {children}
      </main>
      <PlaylistNavigation />
    </>
  );
}
